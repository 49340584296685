<template>
  <div>
    <div
      v-for="(item, index) in $store.state.user.notifications"
      :key="index + 'notification'"
    >
      <b-card>
        <b-media>
          <template #aside>
            <b-avatar
              class="badge-light-primary"
              size="36"
              :variant="item.type"
            >
              <feather-icon icon="BellIcon" />
            </b-avatar>
          </template>
          <p class="media-heading">
            <span class="font-weight-bolder text-black">
              {{ item.notification_heading }}
            </span>
            <span style="float: right">
              {{ getDate(item.created_at) }}
            </span>
          </p>
          <span class="notification-text">
            {{ item.notification_text }}
          </span>
        </b-media>
      </b-card>
    </div>
    <b-link v-if="showLoadMore" @click="loadMore">Load More</b-link>
  </div>
</template>

<script>
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import {
  BCard,
  BCardText,
  BMedia,
  BAvatar,
  BLink,
  BFormCheckbox,
} from "bootstrap-vue";

import Ripple from "vue-ripple-directive";
import store from "@/store";
import CommonServices from "@/apiServices/CommonServices";

export default {
  components: {
    BCard,
    BCardText,
    BMedia,
    BAvatar,
    BLink,
    BFormCheckbox,
    VuePerfectScrollbar,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      limit: 20,
    };
  },
  computed: {
    showLoadMore() {
      const notification = store.state.user.notifications;
      return notification && !(notification.length < this.limit) 
    }
  },
  methods: {
    loadMore() {
      this.limit = this.limit + 20;
      store.dispatch("user/onGetNotifications", {
        limit: this.limit,
      });
    },
    async setAllNotificationsAsRead() {
      try {
        const response = await CommonServices.setAllNotificationsAsRead({
          limit: this.limit,
        });
      } catch (err) {
        console.log("Error in setting all notifications as read  ", err);
      }
    },
    getDate(created_at) {
      if (created_at) {
        let date = new Date(created_at);
        return date.toDateString() + " - " + date.toLocaleTimeString();
      }
    },
  },
  beforeMount() {
    store.dispatch("user/onGetNotifications", {
      limit: this.limit,
    });
    this.setAllNotificationsAsRead();
  },
};
</script>

<style>
.badge-light-primary {
  background-color: #e5d5bc !important;
}
</style>
